var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reviews"},[(_vm.menus.length > 1)?_c('div',{staticClass:"reviews__buttons"},_vm._l((_vm.menus),function(menu,id){return _c('router-link',{key:id,class:[
        'reviews__button-router',
        _vm.isActiveMenu && menu.tag === 'Revisión'
          ? 'router-link-exact-active router-link-active'
          : _vm.isActiveMenuMaintenance && menu.tag === 'Mantenimiento'
          ? 'router-link-exact-active router-link-active'
          : _vm.isActiveMenuavailability && menu.tag === 'Disponibilidad'
          ? 'router-link-exact-active router-link-active'
          : '',
      ],attrs:{"to":menu.to}},[_vm._v(" "+_vm._s(menu.tag)+" "),_c('span',{staticClass:"reviews__menu"},[_vm._v(_vm._s(menu.mobile))])])}),1):_vm._e(),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }