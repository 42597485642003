<template>
  <div class="reviews">
    <div class="reviews__buttons" v-if="menus.length > 1">
      <router-link
        :class="[
          'reviews__button-router',
          isActiveMenu && menu.tag === 'Revisión'
            ? 'router-link-exact-active router-link-active'
            : isActiveMenuMaintenance && menu.tag === 'Mantenimiento'
            ? 'router-link-exact-active router-link-active'
            : isActiveMenuavailability && menu.tag === 'Disponibilidad'
            ? 'router-link-exact-active router-link-active'
            : '',
        ]"
        v-for="(menu, id) in menus"
        :key="id"
        :to="menu.to"
      >
        {{ menu.tag }}
        <span class="reviews__menu">{{ menu.mobile }}</span>
      </router-link>
    </div>
    <router-view />
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import mixinRole from "@/mixins/roles";

  export default {
    mixins: [mixinRole],
    data() {
      return {
        isActiveMenu: false,
        isActiveMenuMaintenance: false,
        isActiveMenuavailability: false,
        menus: [
          {
            to: { path: "/adminReview/?type=allReview" },
            tag: "Revisión",
            mobile: "de habitaciones",
          },
        ],
      };
    },
    watch: {
      $route(to, from) {
        if (to.name === "availability") {
          this.isActiveMenuMaintenance = false;
          this.isActiveMenu = false;
          this.isActiveMenuavailability = true;
          return;
        }
        if (to.fullPath.includes("/adminReview")) {
          this.isActiveMenu = true;
          this.isActiveMenuMaintenance = false;
          this.isActiveMenuavailability = false;
          return;
        }
        if (to.fullPath.includes("/maintenence")) {
          this.isActiveMenuMaintenance = true;
          this.isActiveMenu = false;
          this.isActiveMenuavailability = false;
          return;
        }
      },
    },
    computed: {
      ...mapGetters("user", { getRole: "getRole" }),
    },
    mounted: function() {
      if (this.isRoleAdmin(this.getRole)) {
        this.menus.push({
          to: { name: "maintenence" },
          tag: "Mantenimiento",
        });
        this.menus.unshift({
          to: { path: "/availability" },
          tag: "Asignación",
          mobile: "fija",
        });
      }
    },
  };
</script>

<style lang="scss">
  .reviews {
    width: 100%;
    max-width: 1440px;
    .router-link-exact-active.router-link-active {
      border-bottom: 2px solid $primary;
      background-color: $white;
      font-weight: bold;
    }
    &__menu {
      display: none;
    }
    &__title {
      margin: 5px;
      text-align: center;
    }
    &__buttons {
      @include Row(space-around);
      width: 100%;
      margin: auto;
      padding-top: 50px;
    }
    &__button-router {
      width: 100%;
      padding: 5px 0;
      text-align: center;
      text-decoration: none;
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid $alto;
      &:hover {
        font-weight: bold;
        text-decoration: none;
      }
    }
    @media screen and (min-width: $tablet_width) {
      &__title {
        font-size: $title-desktop;
      }
      &__menu {
        display: inline;
      }
    }
    @include tvWidth() {
      margin: 0 10%;
    }
  }
</style>
